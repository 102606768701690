import { createSlice } from '@reduxjs/toolkit';

import { setUserToken } from '@/utils/userToken';

const initialState = {
  id: '',
  name: '',
  logged: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setLogged: (state, action) => {
      state.logged = action.payload;
    },
  },
});

const { reducer, actions } = userSlice;

export const { setId, setName, setProfile, setLogged } = actions;

export const setInitialUserStateSE = data => dispatch => {
  const { accessToken = '', usuarioAuthorization = {} } = data;
  const { usuario = '', id = '', profile = {} } = usuarioAuthorization;

  setUserToken(accessToken);

  dispatch(setId(id));
  dispatch(setName(usuario));
  dispatch(setProfile(profile));

  dispatch(setLogged(true));
};

export const logoutSE = () => dispatch => {
  sessionStorage.clear();

  dispatch({ type: 'user/LOGOUT' });
};

export default reducer;
