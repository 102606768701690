import React, { lazy } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import CustomRoute from '@/routes/components/CustomRoute';

const Login = lazy(() => import('@/pages/auth/Login'));
const EsqueciMinhaSenha = lazy(() => import('@/pages/auth/EsqueciMinhaSenha'));
const RecuperacaoSenha = lazy(() => import('@/pages/auth/RecuperacaoSenha'));

const AuthRoutes = () => {
  return (
    <Switch>
      <CustomRoute
        exact
        path="/login"
        title="Frotabank - Login"
        component={Login}
      />
      <CustomRoute
        exact
        path="/esqueciMinhaSenha"
        title="Frotabank - Esqueci Minha Senha"
        component={EsqueciMinhaSenha}
      />
      <CustomRoute
        exact
        path="/recuperacaoSenha"
        title="Frotabank - Recuperação da Senha"
        component={RecuperacaoSenha}
      />
      <CustomRoute
        title="Frotabank"
        component={() => <Redirect to="/login" />}
      />
    </Switch>
  );
};

export default AuthRoutes;
