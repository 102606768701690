import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const CustomRoute = ({ title, ...rest }) => {
  useEffect(() => {
    document.title = title;

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [title]);

  return <Route {...rest} />;
};

CustomRoute.propTypes = {
  title: PropTypes.string.isRequired,
};

export default CustomRoute;
