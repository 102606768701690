import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: {},
  all: [],
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setAll: (state, action) => {
      state.all = action.payload;
    },
  },
});

const { reducer, actions } = partnersSlice;

export const { setActive, setAll } = actions;

export const setInitialPartnersStateSE = data => dispatch => {
  const { usuarioAuthorization = {} } = data;
  const { companies = [] } = usuarioAuthorization;

  dispatch(setAll(companies));
};

export const changeActivePartnerSE = data => (dispatch, getState) => {
  const { partner = '' } = data;
  const { all } = getState().partnersReducer;

  const selectedPartner = all.find(item => item.nationalRegister === partner);

  dispatch(setActive(selectedPartner));
};

export const changeActiveCompanyByCnpj = cnpj => (dispatch, getState) => {
  const { all } = getState().partnersReducer;

  const selectedPartner = all.find(item => item.nationalRegister === cnpj);

  dispatch(setActive(selectedPartner));
};

export default reducer;
