import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authorizeCnabPayments: '',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.authorizeCnabPayments = action.payload;
    },
    setLogged: (state, action) => {
      state.logged = action.payload;
    },
  },
});

const { reducer, actions } = profileSlice;

export const { setId, setLogged } = actions;

export const setInitialProfileStateSE = data => dispatch => {
  const { usuarioAuthorization = {} } = data;
  const { profile = {} } = usuarioAuthorization;
  const { authorizeCnabPayments } = profile;

  dispatch(setId(authorizeCnabPayments));
  dispatch(setLogged(true));
};

export default reducer;
