import React from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import { Container } from './styles';

const UserName = () => {
  const { name } = useSelector(state => state.userReducer);

  return (
    <Container>
      <FaUserCircle size={18} />
      <span>{name}</span>
    </Container>
  );
};

export default UserName;
