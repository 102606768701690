import React from 'react';
import { Layout } from 'antd';

const Footer = () => {
  const year = new Date().getFullYear();
  const build = process.env.REACT_APP_BUILD_NUMBER;

  return (
    <Layout.Footer
      style={{
        borderTop: '1px solid #e7eaec',
        background: '#fff',
      }}
    >
      Developed by <strong>Frotabank</strong> &copy; {year}, Versão {build}
    </Layout.Footer>
  );
};

export default Footer;
