import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import AuthContainer from '@/container/auth.container';
import AppContainer from '@/container/app.container';

import Loading from '@/components/Loading';
import ErrorBoundary from '@/components/ErrorBoundary';

const Container = () => {
  const { logged } = useSelector(state => state.userReducer);
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        {logged ? <AppContainer /> : <AuthContainer />}
      </Suspense>
    </ErrorBoundary>
  );
};

export default Container;
