import styled from 'styled-components';

export const Container = styled.div`
  color: #999c9e;
  font-weight: 600;

  display: flex;
  gap: 10px;
  align-items: center;

  > span {
    font-size: 0.875rem;
  }
`;
