import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: [],
  all: [],
};

const funcitionSlice = createSlice({
  name: 'functionAth',
  initialState,
  reducers: {
    setActive: (state, action) => {
      state.active = action.payload;
    },
    setAll: (state, action) => {
      state.all = action.payload;
    },
  },
});

const { reducer, actions } = funcitionSlice;

export const { setActive, setAll } = actions;

export const setInitialFunctionStateSE = data => dispatch => {
  const { usuarioAuthorization = {} } = data;
  const { functionalities = [] } = usuarioAuthorization;

  dispatch(setActive(functionalities));
};

export const changeActiveFunctionSE = data => (dispatch, getState) => {
  const { functionAth = '' } = data;
  const { all } = getState().functionalitiesReducer;

  const selectedFunction = all.find(item => item.name === functionAth);

  dispatch(setActive(selectedFunction));
};

export default reducer;
