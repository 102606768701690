import React from 'react';
import { useLocation } from 'react-router-dom';

import { getPageTitle } from '@/components/Header/constants';

const Header = () => {
  const { pathname } = useLocation();

  const title = getPageTitle(pathname);

  return title ? <div>{title}</div> : null;
};

export default Header;
