import styled from 'styled-components';

export const TopHeaderContainer = styled.div`
  padding: 0 2px;
  height: 60px;
  width: 100%;
  background: #f8f8f8;
  position: sticky;
  top: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: flex-start;

    > span {
      font-size: 0.6785rem;
      text-align: center;

      flex: 1;
    }

    .nav-items {
      display: none;
    }
  }

  .nav-items {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    > button {
      outline: none;
      border: none;
      background: transparent;
    }
  }
`;
