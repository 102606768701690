import React, { createContext, useState } from 'react';

export const CardContext = createContext({});

export function CardContextProvider({ children }) {
  const [visibleModal, setVisibleModal] = useState(false);

  return (
    <CardContext.Provider
      value={{
        setVisibleModal,
        visibleModal,
      }}
    >
      {children}
    </CardContext.Provider>
  );
}
