const isProductionBuild =
  process.env.NODE_ENV === 'production' ||
  process.env.UI_THEME_ENV === 'production';

export const theme = {
  colors: {
    primary: isProductionBuild ? '#007881' : '#9a8426',
    secondary: isProductionBuild ? '#18c6b2' : '#ebc522',
    tertiary: isProductionBuild ? '#48B7A6' : '#ebc522',
    white: '#fff',
  },
};
