import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppLayout from '@/layouts/app.layout';

const LoggedContainer = () => {
  return (
    <BrowserRouter>
      <AppLayout />
    </BrowserRouter>
  );
};

export default LoggedContainer;
