import React, { createContext, useState } from 'react';

export const ModalInfoContext = createContext({});

export function ModalInfoContextProvider({ children }) {
  const [showModal, setShowModal] = useState(false);
  return (
    <ModalInfoContext.Provider
      value={{
        setShowModal,
        showModal,
      }}
    >
      {children}
    </ModalInfoContext.Provider>
  );
}
