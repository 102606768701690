import React, { useState } from 'react';
import { Button, Menu, notification } from 'antd';
import { FiLogOut } from 'react-icons/fi';
import { IoReorderThree } from 'react-icons/io5';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { logoutSE } from '@/store/slices/user';
import links from '@/constants/links';

import { OpenButton, CustomDrawer, LogoutButton } from './styles';

const MobileSidebar = () => {
  const { active = [] } = useSelector(state => state.functionalitiesReducer);
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const queryClient = useQueryClient();

  const { pathname } = useLocation();
  const { push } = useHistory();

  const logoutConfirmation = () => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button
        type="primary"
        onClick={() => {
          notification.close(key);
          queryClient.clear();
          return dispatch(logoutSE());
        }}
      >
        Sim, eu tenho certeza
      </Button>
    );

    notification.open({
      message: 'Saída',
      description: 'Tem certeza que deseja sair?',
      btn,
      key,
    });
  };

  const openSidebar = () => {
    setIsOpen(true);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const navigate = link => {
    const differentPage = link !== pathname;

    if (differentPage) {
      closeSidebar();
      push(link);
    }
  };

  return (
    <>
      <OpenButton type="primary" htmlType="button" onClick={openSidebar}>
        <IoReorderThree color="#fff" size={16} />
      </OpenButton>
      <CustomDrawer
        visible={isOpen}
        placement="right"
        onClose={closeSidebar}
        closable={false}
      >
        <Menu mode="inline" selectedKeys={[pathname]}>
          {links.map(item => {
            const actions = active.map(iten => iten.action);
            const linkAutorizate = actions.indexOf(item.link);

            if (
              linkAutorizate !== -1 ||
              item.link === '/dashboard' ||
              item.link === '/relatorios' ||
              item.link === '/usuarios' ||
              item.link === '/empresas' ||
              item.link === '/profiles'
            ) {
              if (item.subItems) {
                return (
                  <Menu.SubMenu
                    key={item.id}
                    icon={item.icon}
                    title={item.title}
                  >
                    {item.subItems.map(subItem => (
                      <Menu.Item
                        key={subItem.id}
                        onClick={() => navigate(subItem.link)}
                      >
                        {subItem.title}
                      </Menu.Item>
                    ))}
                  </Menu.SubMenu>
                );
              }

              return (
                <Menu.Item
                  key={item.id}
                  icon={item.icon}
                  onClick={() => navigate(item.link)}
                >
                  {item.title}
                </Menu.Item>
              );
            }

            return null;
          })}
        </Menu>

        <LogoutButton type="button" onClick={() => logoutConfirmation()}>
          <FiLogOut size={20} color="#999c9e" />
          <p>Sair</p>
        </LogoutButton>
      </CustomDrawer>
    </>
  );
};

export default MobileSidebar;
