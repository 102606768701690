const PAGE_TITLES = {
  '/dashboard': null,
  '/depositoDireto': ' ',
  '/trocoDigital': ' ',
  '/detalhamentoTrocoDigital': ' ',
  '/historico': ' ',
  '/transferencia': ' ',
  '/saque': ' ',
  '/relatorios/deposito': ' ',
  '/relatorios/saque': ' ',
  '/relatorios/trocoDigital': ' ',
  '/usuarios': ' ',
  '/usuarios/cadastro': ' ',
  '/usuarios/edicao': ' ',
  '/empresas': ' ',
  '/empresas/cadastro': ' ',
  '/empresas/edicao': ' ',
  '/profiles': ' ',
  '/profiles/cadastro': ' ',
  '/profiles/edicao': ' ',
  '/extratos': ' ',
  '/limiteOperacoes': ' ',
  '/limiteOperacoesGestor': ' ',
  '/saldo': ' ',
  '/deposito': ' ',
  '/depositoParceiro': ' ',
  '/relatorios/transacoesFinanceiro': ' ',
  '/relatorios/transacoesPosto': ' ',
  '/relatorios/transacoesPDV': ' ',
  '/relatorios/reciboExtrato ': ' ',
  '/consultas/chequeTroco': ' ',
  '/consultas/cartaFrete': ' ',
  '/adicionais/pessoas': ' ',
  '/adicionais/pessoas/vincular': ' ',
  '/adicionais/cartoes': ' ',
  '/adicionais/cartoes/vincular': ' ',
  '/cartaoDespesas/vincularCartoesReutilizaveis': ' ',
  '/relatorios/transacoesPDV/vincularCupom': ' ',
  '/adicionais/cartoesPagamentos': ' ',
  '/adicionais/cartoesPagamentos/vincular': ' ',
  '/adicionais/cartoesPagamentos/aporte': ' ',
  '/adicionais/cartoesPagamentos/aporteMultiplo': ' ',
  '/adicionais/cartoesPagamentos/extrato': ' ',
  '/contasCartoesPagamentos': ' ',
  '/cartaoDespesas': ' ',
  '/acesso/horarioPermitido': ' ',
  '/acesso/horarioPermitido/cadastrar': ' ',
  '/acesso/horarioPermitido/atualizar': ' ',
  '/cartoes/importarCartao': ' ',
};

export const getPageTitle = pathname => {
  return PAGE_TITLES[pathname];
};
