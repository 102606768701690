import { combineReducers } from '@reduxjs/toolkit';

import userReducer from './user';
import partnersReducer from './partners';
import functionalitiesReducer from './functionalities';
import profileReducer from './profile';

const appReducer = combineReducers({
  partnersReducer,
  userReducer,
  functionalitiesReducer,
  profileReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'user/LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
